import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appInputControl]',
  standalone: true,
})
export class InputControlDirective {
  @Input('appInputControl') controlType = '';

  regexObj = {
    alphabetOnly: /[^a-zA-Z]/g,
    alphabetWithSpace: /[^a-zA-Z ]/g,
    numberOnly: /[^0-9]/g,
    noWhiteSpace: / /g,
    name: /[^a-zA-Z ']/g
  };

  constructor(private elementRef: ElementRef, private rendrer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInputChange() {
    const inputElement: HTMLInputElement = this.elementRef.nativeElement;
    this.rendrer.setProperty(
      inputElement,
      'value',
      inputElement.value.replace(
        this.regexObj[this.controlType as keyof typeof this.regexObj],
        ''
      )
    );
  }
}
